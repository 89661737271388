import { Button, Col, Modal, ModalProps, Row } from 'react-bootstrap';

type Props = {
  title: string;
  onConfirm: () => void;
} & ModalProps;

const DeleteItemModal = ({ title, show, onHide, onConfirm }: Props) => {
  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Body>
        <Row className="mt-4">
          <Col className="text-center">{title}</Col>
        </Row>
        <Row style={{ display: 'flex', justifyContent: 'space-around' }} className="mt-5 pl-1">
          <Button variant="success" className="w-30" onClick={onConfirm}>
            Confirm
          </Button>
          <Button className="w-30" onClick={onHide}>
            Cancel
          </Button>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default DeleteItemModal;
