import * as React from 'react';
import { Button, Col, Form, Modal, ModalProps, Row } from 'react-bootstrap';

type Props = {
  loading: boolean;
  onAdd: (name: string) => void;
} & ModalProps;

const CreateEmojiPackModal = ({ show, onHide, onAdd, loading }: Props) => {
  const [name, setName] = React.useState('');

  const handleSubmit = () => {
    if (!name.length) {
      alert('Please, enter valid name');
      return;
    }
    onAdd(name);
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Body>
        <Row>
          <Col xs={3}>Name</Col>
          <Col xs={6}>
            <Form.Control
              name="diamondPrize"
              required
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </Col>
        </Row>
        <Row className="mt-3 pl-1">
          <Button disabled={loading} onClick={handleSubmit}>
            Add
          </Button>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default CreateEmojiPackModal;
