import { ROLE } from '../../../../../../config';
import { ViewMap } from '../../../../../../types';
import Badges from './Badges';
import EmojiPacks from './EmojiPacks';
import Frames from './Frames';
import Stickers from './Stickers';
import Vinyls from './Vinyls';

const views: { sp3: ViewMap } = {
  sp3: {
    frames: { component: Frames, permission: [ROLE.ADMIN, ROLE.DEVELOPER, ROLE.DASHBOARD] },
    stickers: { component: Stickers, permission: [ROLE.ADMIN, ROLE.DEVELOPER, ROLE.DASHBOARD] },
    vinyls: { component: Vinyls, permission: [ROLE.ADMIN, ROLE.DEVELOPER, ROLE.DASHBOARD] },
    badges: { component: Badges, permission: [ROLE.ADMIN, ROLE.DEVELOPER, ROLE.DASHBOARD] },
    emojis: { component: EmojiPacks, permission: [ROLE.ADMIN, ROLE.DEVELOPER, ROLE.DASHBOARD] },
  },
};

export default views;
