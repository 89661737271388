import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { Button, Card, Form } from 'react-bootstrap';
import { useMutation } from '@apollo/client';
import JSZip from 'jszip';

import { LoadingLogo } from '../../../../devtools/components/Modal';
import { 
  DeleteEmojiPackMutation,
  DeleteEmojiPackMutationVariables,
  Emoji,
  EmojiPack,
  EmojiPackLayout,
  UpdateEmojiPackMutation,
  UpdateEmojiPackMutationVariables,
  UploadBatchEmojiAssetMutation,
  UploadBatchEmojiAssetMutationVariables,
  UploadEmojiPackAssetMutation,
  UploadEmojiPackAssetMutationVariables
} from '../../../../../../__gqltypes__';
import DeleteItemModal from './DeleteItemModal';
import DragUpload from './DragUpload';

import { DELETE_EMOJI_PACK, UPDATE_EMOJI_PACK, UPLOAD_BATCH_EMOJI_ASSET, UPLOAD_EMOJI_PACK_ASSET } from '../../../components/apolloQueries';
import EmojiView from './EmojiView';
import EmojiFactory from './EmojiFactory';


type Props = {
  item: EmojiPack;
  refetch: () => void;
};

function validateUtfCode(utf: string): boolean {
  // we need to check if utf code is in PUA [E000, F8FF]
  const utfCodeNum = +utf;
  if (utfCodeNum > 0xF8FF || utfCodeNum < 0xE000) {
    return false;
  }
  return true;
}

function extractNameAndUtf(filename: string): { name: string; utfCode: string; ext: string; }  {
  if (!filename.includes('.') || !filename.includes('_')) {
    throw new Error(`Wrong format of filename, missing extension or "_" separator. Filename: ${filename}`);
  }
  const [nameWithoutExt, ext] = filename.split('.');
  const [name, utfCode] = nameWithoutExt.split('_');
  
  // if (!validateUtfCode(utfCode)) {
  //   throw new Error(`Incorrect value of utf code. Code has to be in range: [E000, F8FF]. Filename: ${filename}`);
  // }

  return {
    name, 
    utfCode,
    ext,
  };
}

export default function EmojiPackView({item, refetch}: Props): JSX.Element {
  const [itemData, setItemData] = useState(item);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [newIndices, setNewIndices] = useState<Array<number>>([]);

  useEffect(() => {
    setItemData(item);
  }, [item]);

  const history = useHistory();
  
  const reloadEmojiPacks = useCallback(() => {
    refetch();
  }, [refetch]);

  const [updateItem, {loading: updateLoading}] = useMutation<
    UpdateEmojiPackMutation, 
    UpdateEmojiPackMutationVariables
  >(UPDATE_EMOJI_PACK, { onCompleted: reloadEmojiPacks });

  const [deleteItem, {loading: deleteLoading}] = useMutation<
    DeleteEmojiPackMutation, 
    DeleteEmojiPackMutationVariables
  >(DELETE_EMOJI_PACK, {onCompleted: reloadEmojiPacks});

  const [uploadEPAsset, { loading: uploadLoading}] = useMutation<
    UploadEmojiPackAssetMutation,
    UploadEmojiPackAssetMutationVariables
  >(UPLOAD_EMOJI_PACK_ASSET);

  const [uploadBatchEAsset, { loading: uploadBatchLoading}] = useMutation<
    UploadBatchEmojiAssetMutation,
    UploadBatchEmojiAssetMutationVariables
  >(UPLOAD_BATCH_EMOJI_ASSET);


  let maxEmojis = 30;
  if (itemData.layout === EmojiPackLayout.IAP) {
    maxEmojis = 15;
  }

  const loading = updateLoading || deleteLoading || uploadLoading || uploadBatchLoading;
  const handleUpdate = useCallback((newData: EmojiPack) => {
    updateItem({
      variables: {
        input: {
          id: newData.id,
          name: newData.name,
          asset: newData.asset,
          layout: newData.layout,
          emojis: newData.emojis.map((emoji) => ({
            id: emoji.id,
            asset: emoji.asset,
            name: emoji.name,
            utfCode: emoji.utfCode,
          })),
        }
      }
    }).then(() => {
      setNewIndices([]);
    });
  }, [updateItem]);

  const handleDelete = () => {
    deleteItem({variables: {
      id: itemData.id,
    }}).finally(() => {
      history.push('sp3/liveops/items/emojipacks');
      setShowDeleteModal(false);
    });
  };

  const handleEPUpload = useCallback((file: Blob) => {
    uploadEPAsset({variables: {
      input: {
        id: item.id,
        file,
      }
    }}).then(({data}) => {
      setItemData((prev) => {
        const newData = {...prev};
        if (data) {
          newData.asset = data?.uploadEmojiPackAsset.emojiPack.asset;
        }
        return newData;
      });
    });
  }, [item.id, uploadEPAsset]);

  const onGenerate = useCallback((files: Array<File>, onError: () => void) => {
    const indices: Array<number> = [];
    const emojis: Array<Emoji & { ext: string; }> = files.map((file, index) => {
      const {name, utfCode, ext} = extractNameAndUtf(file.name);
      console.log(`Filename: ${name}, utfCode: ${utfCode}`);
      indices.push(index);
      return {
        __typename: 'Emoji',
        id: `${itemData.id}.${utfCode}`, // [TO_DO][OPT] wrap in helper function
        name,
        utfCode,
        asset: '',
        cost: {
          __typename: 'CurrencyDiamond',
          amount: 0,
          id: 'CurrencyDiamon',
        },
        ext,
      };
    });
    setNewIndices((prev) => prev.concat(indices));
    
    // create zip file from all files
    const archive = new JSZip();
    files.forEach((file, index) => {
      const emoji = emojis[index];
      const codePoint_ = emoji.name.codePointAt(0) ?? 0; 
      const codePoint = codePoint_.toString(16);
      archive.file(`${codePoint}_${emoji.utfCode}.${emoji.ext}`, file.arrayBuffer(), {binary: true});
    });
    archive.generateAsync({type:'blob'}).then((fileBlob) => {
      return uploadBatchEAsset({variables: {
        input: {
          file: fileBlob
        }
      }});
    }).then(({data}) => {
      if (data?.uploadBatchEmojiAsset) {
        data.uploadBatchEmojiAsset.assets.forEach((asset, index) => {
          emojis[index].asset = asset;
        });
        setItemData((prev) => ({...prev, emojis}));
      } else {
        onError();
      }
    }).catch(() => {
      onError();
    });
  }, [itemData, uploadBatchEAsset]);

  const handleAddEmoji = useCallback(() => {
    const e: Emoji = {
      __typename: 'Emoji',
      name: 'New Emoji',
      asset: '',
      utfCode: 'F8FF',
      cost: {
        __typename: 'CurrencyDiamond',
        amount: 0,
        id: 'CurrencyDiamon',
      },
      id: itemData.id,
    };

    setNewIndices((prev) => prev.concat([itemData.emojis.length]));
    setItemData((prev) => ({...prev, emojis: prev.emojis.concat(e)}));
  }, [itemData]);

  const handleDeleteEmoji = useCallback((index: number) => {
    const newEmojis = itemData.emojis.filter((val, idx) => idx !== index);
    const newItemData = {...itemData, emojis: newEmojis};
    setNewIndices((prev) => prev.filter((val) => val !== index));
    setItemData(newItemData);
    handleUpdate(newItemData);
  }, [handleUpdate, itemData]);
  
  const handleMoveEmoji = useCallback((index: number, isUp: boolean) => {
    const inc = isUp ? -1 : 1;
    const newEmojis = [...itemData.emojis];
    [newEmojis[index], newEmojis[index + inc]] = [newEmojis[index + inc], newEmojis[index]];
    setItemData((prev) => ({...prev, emojis: newEmojis}));
  }, [itemData.emojis]);

  return (
    <>
      <Card className="mt-3">
        <LoadingLogo show={loading} />
        <DeleteItemModal
          title={`Delete "${itemData?.name}" Emoji Pack?`}
          show={showDeleteModal && !loading}
          onHide={() => setShowDeleteModal(false)}
          onConfirm={handleDelete}
        />
        <Card.Header className="text-center d-flex justify-content-between">
          <h4>{itemData?.name}</h4>
          <div>
            <Button className="mr-3" variant="danger" onClick={() => setShowDeleteModal(true)}>
              Delete
            </Button>
            <Button onClick={() => handleUpdate(itemData)}>Save</Button>
          </div>
        </Card.Header>
        <Card.Body style={{ height: '100%' }}>
          <Form>
            <Form.Group>
              <Form.Label>Id</Form.Label>
              <Form.Control placeholder={itemData.id} disabled />
            </Form.Group>
            <Form.Group>
              <Form.Label>Name</Form.Label>
              <Form.Control
                placeholder=""
                value={itemData.name}
                onChange={(e) => setItemData({ ...itemData, name: e.target.value })}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Layout</Form.Label>
              <Form.Control 
                as="select"
                value={itemData.layout} 
                onChange={(e) => setItemData({...itemData, layout: e.target.value as EmojiPackLayout})}>
                {Object.keys(EmojiPackLayout).map((type) => (
                  <option value={type} key={type}>
                    {type}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
            <Form.Group>
              <Form.Label>Asset</Form.Label>
              <Form.Control value={itemData.asset} disabled />
            </Form.Group>
            <DragUpload onUpload={handleEPUpload} />
            {itemData.emojis.length === 0 ? (
                <EmojiFactory onGenerate={onGenerate}/>
            ) : (
                <Form.Group>
                  {itemData.emojis.length < maxEmojis && (
                    <Form.Row>
                      <Button onClick={handleAddEmoji}>Add Emoji</Button>
                    </Form.Row>)
                  }
                  <Form.Label>Emojis</Form.Label>
                  {itemData.emojis.map((emoji, index) => {
                    return (
                    <EmojiView 
                      key={emoji.id}
                      item={emoji}
                      index={index}
                      isNew={newIndices.includes(index)}
                      emojiPackSize={itemData.emojis.length}
                      onUpdate={reloadEmojiPacks}
                      onDelete={handleDeleteEmoji}
                      onMove={handleMoveEmoji}/>
                    );
                  })}
                </Form.Group>
            )}
          </Form>
        </Card.Body>
      </Card>
    </>
  );
}