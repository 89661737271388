import _ from 'lodash';
import { ChangeEvent, useEffect, useState } from 'react';
import { MetricOrderBy, MetricTimestamp, MetricType, ServerEnv, ServerMode } from '../../../../../../__gqltypes__';
import {
  BackgroundTypeChoices,
  InteractiveMonitoringPlaygroundOptions,
  NumberOfTimestamps,
  NumberOfTimestampsChoices,
  RequestTrackingChoices,
  RequestTrackingMethod,
  ServerBetaProdEnvChoices,
  ServerModeChoices,
  ServerTestEnvChoices,
  SortMethodChoices,
  SortMethodRCChoices,
  TimeSelectionChoices,
  TimeSelectionMethod,
  TimestampChoices,
  TimestampChoicesDisabled,
  TypeChoices,
} from '../logic/formOptions';
import { useChoiceButtonGroupProps, useListOfNameProps, useNumberInputProps, useStartEnd } from './inputHooks';

export default function usePlayground(initialValues: Partial<InteractiveMonitoringPlaygroundOptions>) {
  const modeProps = useChoiceButtonGroupProps<ServerMode>(ServerModeChoices, initialValues.mode || ServerMode.API);

  const beta = process.env.REACT_APP_NAMESPACE === 'beta';
  const prod = process.env.REACT_APP_NAMESPACE === 'default' || process.env.REACT_APP_NAMESPACE === 'prod';

  const envProps = useChoiceButtonGroupProps<ServerEnv>(
    beta || prod ? ServerBetaProdEnvChoices : ServerTestEnvChoices,
    initialValues.env || (beta || prod ? ServerEnv.PROD : ServerEnv.TEST)
  );

  const timeSelectionProps = useChoiceButtonGroupProps<TimeSelectionMethod>(
    TimeSelectionChoices,
    initialValues.timeSelection || TimeSelectionMethod.PREVIOUS
  );

  const requestTrackingProps = useChoiceButtonGroupProps<RequestTrackingMethod>(
    RequestTrackingChoices,
    initialValues.requestTracking || RequestTrackingMethod.MOST_IMPORTANT
  );

  const typeProps = useChoiceButtonGroupProps<MetricType>(
    modeProps.choice === ServerMode.BACKGROUND ? BackgroundTypeChoices : TypeChoices,
    initialValues.type || MetricType.GQLREQUEST
  );

  const isRC = typeProps.choice === MetricType.REDISREQUESTCOUNT;
  const sortMethodProps = useChoiceButtonGroupProps<MetricOrderBy | 'ALL'>(
    isRC ? SortMethodRCChoices : SortMethodChoices,
    initialValues.sortMethod || MetricOrderBy.MOST_POPULAR_SORT
  );

  const timestampChoicesDisabled = () => {
    TimestampChoicesDisabled[MetricTimestamp.MINUTELY] = typeProps.choice !== MetricType.GQLREQUEST;
    return TimestampChoicesDisabled;
  };

  const timestampProps = useChoiceButtonGroupProps<MetricTimestamp>(
    TimestampChoices,
    initialValues.timestamp || MetricTimestamp.HOURLY,
    timestampChoicesDisabled()
  );

  const maxNumberOfTimestampsProps = useChoiceButtonGroupProps<NumberOfTimestamps>(
    NumberOfTimestampsChoices[timestampProps.choice as MetricTimestamp],
    initialValues.maxNumberOfTimestamps || NumberOfTimestamps.LAST_72_HOURS
  );

  const { reset: requestNamesReset, props: requestNamesProps } = useListOfNameProps(initialValues.requestNames || []);

  const { intValue: maxNumberOfRequests, ...maxNumberOfRequestsProps } = useNumberInputProps(
    initialValues.maxNumberOfRequests || 5
  );

  const startEndProps = useStartEnd(initialValues.start, initialValues.end);

  const [compare, setCompare] = useState(initialValues.compare || false);
  const { intValue: compareSkip, ...compareSkipProps } = useNumberInputProps(initialValues.compareSkip || 1);

  const [includeSubscriptions, setincludeSubscriptions] = useState(initialValues.includeSubscriptions || false);

  useEffect(() => {
    requestNamesReset(typeProps.choice === initialValues.type);
  }, [typeProps.choice, requestNamesReset, initialValues.type]);

  return {
    props: {
      env: envProps,
      mode: modeProps,
      type: typeProps,
      timestamp: timestampProps,
      timeSelection: timeSelectionProps,
      requestTracking: requestTrackingProps,
      maxNumberOfTimestamps: maxNumberOfTimestampsProps,
      maxNumberOfRequests: maxNumberOfRequestsProps,
      startEnd: startEndProps,
      sortMethod: sortMethodProps,
      requestNames: {
        ...requestNamesProps,
        allowAddByModel:
          typeProps.choice === MetricType.SPANNERREQUEST || typeProps.choice === MetricType.SPANNERRESPONSESIZE,
      },
      compare: { checked: compare, onChange: (e: ChangeEvent<HTMLInputElement>) => setCompare(e.target.checked) },
      compareSkip: compareSkipProps,
      includeSubscriptions: {
        checked: includeSubscriptions,
        onChange: (e: ChangeEvent<HTMLInputElement>) => setincludeSubscriptions(e.target.checked),
      },
    },
    values: {
      env: envProps.choice,
      mode: modeProps.choice,
      type: typeProps.choice,
      timestamp: timestampProps.choice,
      timeSelection: timeSelectionProps.choice,
      requestTracking: requestTrackingProps.choice,
      maxNumberOfTimestamps: maxNumberOfTimestampsProps.choice,
      maxNumberOfRequests,
      start: startEndProps.start,
      end: startEndProps.end,
      sortMethod: sortMethodProps.choice,
      requestNames: requestNamesProps.names,
      compare,
      compareSkip,
      includeSubscriptions,
    },
  };
}
