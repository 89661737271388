import { MetricOrderBy, MetricTimestamp, MetricType, ServerEnv, ServerMode } from '../../../../../../__gqltypes__';

export const TypeChoices = {
  [MetricType.GQLREQUEST]: { label: 'Request GraphQL' },
  [MetricType.SPANNERREQUEST]: { label: 'Spanner Query' },
  [MetricType.SPANNERRESPONSESIZE]: { label: 'Spanner response size' },
  [MetricType.REDISREQUEST]: { label: 'Redis Request' },
  [MetricType.REDISREQUESTCOUNT]: { label: 'Redis Request Count' },
};

export const BackgroundTypeChoices = {
  [MetricType.SPANNERREQUEST]: TypeChoices[MetricType.SPANNERREQUEST],
  [MetricType.SPANNERRESPONSESIZE]: TypeChoices[MetricType.SPANNERRESPONSESIZE],
};

export const TimestampChoices = {
  [MetricTimestamp.HOURLY]: { label: MetricTimestamp.HOURLY },
  [MetricTimestamp.DAILY]: { label: MetricTimestamp.DAILY },
  [MetricTimestamp.WEEKLY]: { label: MetricTimestamp.WEEKLY },
  [MetricTimestamp.MONTHLY]: { label: MetricTimestamp.MONTHLY },
  [MetricTimestamp.QUARTERLY]: { label: MetricTimestamp.QUARTERLY },
  [MetricTimestamp.YEARLY]: { label: MetricTimestamp.YEARLY },
  [MetricTimestamp.MINUTELY]: { label: MetricTimestamp.MINUTELY },
};

export const TimestampChoicesDisabled = {
  [MetricTimestamp.HOURLY]: false,
  [MetricTimestamp.DAILY]: false,
  [MetricTimestamp.WEEKLY]: false,
  [MetricTimestamp.MONTHLY]: false,
  [MetricTimestamp.QUARTERLY]: false,
  [MetricTimestamp.YEARLY]: false,
  [MetricTimestamp.MINUTELY]: false,
};

export const NumberOfTimestampsChoices = {
  [MetricTimestamp.HOURLY]: {
    LAST_12_HOURS: { label: 'last 12 hours' },
    LAST_24_HOURS: { label: 'last 24 hours' },
    LAST_72_HOURS: { label: 'last 3 days (72 hours)' },
    LAST_168_HOURS: { label: 'last week (172 hours)' },
  },
  [MetricTimestamp.DAILY]: {
    LAST_7_DAYS: { label: 'last week (7 days)' },
    LAST_14_DAYS: { label: 'last 2 weeks (14 days)' },
    LAST_30_DAYS: { label: 'last month (30 days)' },
    LAST_90_DAYS: { label: 'last 3 month (90 days)' },
  },
  [MetricTimestamp.WEEKLY]: {
    LAST_4_WEEKS: { label: 'last month (4 weeks)' },
    LAST_12_WEEKS: { label: 'last 3 months (12 weeks)' },
    LAST_52_WEEKS: { label: 'last year (52 weeks)' },
  },
  [MetricTimestamp.MONTHLY]: {
    LAST_6_MONTHS: { label: 'last 6 months' },
    LAST_12_MONTHS: { label: 'last year (12 months)' },
    LAST_24_MONTHS: { label: 'last 2 year (24 months)' },
    LAST_60_MONTHS: { label: 'last 5 years (60 months)' },
  },
  [MetricTimestamp.QUARTERLY]: {
    LAST_4_QUARTERS: { label: 'last year (4 quarters)' },
    LAST_8_QUARTERS: { label: 'last 2 year (8 quarters)' },
    LAST_20_QUARTERS: { label: 'last 5 years (20 quarters)' },
  },
  [MetricTimestamp.YEARLY]: {
    LAST_5_YEARS: { label: 'last 5 years' },
  },
  [MetricTimestamp.MINUTELY]: {
    LAST_LIVESHOW_1: { label: 'prev LiveShow' },
    LAST_LIVESHOW_2: { label: '2nd prev LiveSHow' },
    LAST_LIVESHOW_3: { label: '3rd prev LiveSHow' },
    LAST_LIVESHOW_4: { label: '4th prev LiveSHow' },
    LAST_LIVESHOW_5: { label: '5th prev LiveSHow' },
  },
};

export enum TimeSelectionMethod {
  BETWEEN = 'BETWEEN',
  PREVIOUS = 'PREVIOUS',
}
export enum RequestTrackingMethod {
  MOST_IMPORTANT = 'MOST_IMPORTANT',
  SPECIFIC = 'SPECIFIC',
}

export const TimeSelectionChoices = {
  [TimeSelectionMethod.BETWEEN]: { label: 'Between 2 specific dates' },
  [TimeSelectionMethod.PREVIOUS]: { label: 'Select some number of hours, days, ...' },
};

export const RequestTrackingChoices = {
  [RequestTrackingMethod.MOST_IMPORTANT]: { label: 'All or most important requests' },
  [RequestTrackingMethod.SPECIFIC]: { label: 'Specific requests by name' },
};

export const SortMethodChoices = {
  ALL: { label: 'All (up to 400)' },
  [MetricOrderBy.MOST_POPULAR_SORT]: { label: 'Most popular' },
  [MetricOrderBy.WORST_AVERAGE_TIME_SORT]: { label: 'worst average execution time' },
  [MetricOrderBy.WORST_CUMULATED_TIME_SORT]: { label: 'Worst cumulated execution time' },
  [MetricOrderBy.WORST_CUMULATED_BY_PERIOD_SORT]: { label: 'Worst periodic cumulated execution time' },
};

export const SortMethodRCChoices = {
  ALL: { label: 'All (up to 400)' },
  [MetricOrderBy.MOST_POPULAR_SORT]: { label: 'Most popular' },
  [MetricOrderBy.WORST_CACHE_MISS_RATIO]: { label: 'Worst cache miss ratio' },
  [MetricOrderBy.WORST_TIMEOUT_RATIO]: { label: 'Worst timeout ratio' },
};

export const ServerModeChoices = {
  [ServerMode.API]: { label: 'Api mode' },
  [ServerMode.ADMIN]: { label: 'Admin mode' },
  [ServerMode.BACKGROUND]: { label: 'Background mode' },
};
export const ServerBetaProdEnvChoices = {
  [ServerEnv.BETA]: { label: 'Beta env' },
  [ServerEnv.PROD]: { label: 'Prod env' },
};

export const ServerTestEnvChoices = {
  [ServerEnv.TEST]: { label: 'Test env' },
};

export enum NumberOfTimestamps {
  LAST_LIVESHOW_1 = 'LAST_LIVESHOW_1',
  LAST_LIVESHOW_2 = 'LAST_LIVESHOW_2',
  LAST_LIVESHOW_3 = 'LAST_LIVESHOW_3',
  LAST_LIVESHOW_4 = 'LAST_LIVESHOW_4',
  LAST_LIVESHOW_5 = 'LAST_LIVESHOW_5',
  LAST_12_HOURS = 'LAST_12_HOURS',
  LAST_24_HOURS = 'LAST_24_HOURS',
  LAST_72_HOURS = 'LAST_72_HOURS',
  LAST_168_HOURS = 'LAST_168_HOURS',
  LAST_7_DAYS = 'LAST_7_DAYS',
  LAST_14_DAYS = 'LAST_14_DAYS',
  LAST_30_DAYS = 'LAST_30_DAYS',
  LAST_90_DAYS = 'LAST_90_DAYS',
  LAST_4_WEEKS = 'LAST_4_WEEKS',
  LAST_12_WEEKS = 'LAST_12_WEEKS',
  LAST_52_WEEKS = 'LAST_52_WEEKS',
  LAST_6_MONTHS = 'LAST_6_MONTHS',
  LAST_12_MONTHS = 'LAST_12_MONTHS',
  LAST_24_MONTHS = 'LAST_24_MONTHS',
  LAST_60_MONTHS = 'LAST_60_MONTHS',
  LAST_4_QUARTERS = 'LAST_4_QUARTERS',
  LAST_8_QUARTERS = 'LAST_8_QUARTERS',
  LAST_20_QUARTERS = 'LAST_20_QUARTERS',
  LAST_5_YEARS = 'LAST_5_YEARS',
}

export type InteractiveMonitoringPlaygroundOptions = {
  type: MetricType;
  mode?: ServerMode;
  env?: ServerEnv;
  timestamp: MetricTimestamp;
  timeSelection: TimeSelectionMethod;
  requestTracking: RequestTrackingMethod;
  maxNumberOfTimestamps: NumberOfTimestamps;
  maxNumberOfRequests: number;
  start: string | undefined;
  end: string | undefined;
  sortMethod: MetricOrderBy | 'ALL';
  requestNames: string[];
  compare?: boolean;
  compareSkip?: number;
  includeSubscriptions?: boolean;
};
