import * as React from 'react';
import { useMutation } from '@apollo/client';
import { Modal, Row } from 'react-bootstrap';

import DragUpload from './DragUpload';
import { OnValidateItem } from './typedef';
import { UploadEmojiFontMutation, UploadEmojiFontMutationVariables } from '../../../../../../__gqltypes__';
import { UPLOAD_EMOJIS_FONT } from '../../../components/apolloQueries';
import { LoadingLogo } from '../../../../devtools/components/Modal';

type Props = {
  onCancel: () => void;
};

export function UploadFontModal({onCancel}:Props): JSX.Element {

  const [upload, {loading}] = useMutation<
    UploadEmojiFontMutation,
    UploadEmojiFontMutationVariables>(UPLOAD_EMOJIS_FONT);
  const onUpload = (file: Blob) => {
    upload({ variables: {
        input: {
          file,
        }
    }}).finally(() => {
      onCancel();
    });
  };

  return (
    <Modal onHide={onCancel} show={true}>
        <Modal.Body>
          {loading ? (
            <LoadingLogo show={loading} />
          ) : (
            <Row>
              <DragUpload onUpload={onUpload}/>
            </Row>
          )}
        </Modal.Body>
    </Modal>
  );
}