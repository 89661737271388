import { useMutation, useQuery } from '@apollo/client';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import { LinkContainer } from 'react-router-bootstrap';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import type { CreateItemMutation, CreateItemMutationVariables } from '../../../../../../__gqltypes__';
import { CreateItemInputType, CurrencyTypeInput } from '../../../../../../__gqltypes__';
import { LoadingLogo } from '../../../../devtools/components/Modal';
import { CREATE_ITEM } from '../../../components/apolloQueries';
import { getQuery } from '../utils';
import ItemView from './ItemView';

export function indexItems<T extends { id: string }>(list: T[]) {
  const indexedItems: Record<string, T> = {};

  list.forEach((item) => {
    indexedItems[item.id] = item;
  });

  return indexedItems;
}

const ItemsView = <T extends string>({ type }: { type: T }) => {
  const match = useRouteMatch();

  const [items, setItems] = useState<Record<string, any> | null>(null);
  const { query, key } = getQuery(type);
  const { data, loading, refetch } = useQuery(query, { fetchPolicy: 'no-cache' });

  useEffect(() => {
    if (data) {
      setItems(indexItems(data.liveOps[key].list));
    }
  }, [data, key]);

  const [createItem, { loading: createLoading }] = useMutation<CreateItemMutation, CreateItemMutationVariables>(
    CREATE_ITEM,
    { onCompleted: () => refetch() }
  );

  const handleCreate = () => {
    if (!items) return;

    const { typeQ } = getQuery(type);
    const firstItem = Object.values(items)[0];
    const asset = firstItem ? firstItem.asset : 'https://example.com';

    createItem({
      variables: {
        input: {
          name: `New ${type}`,
          cost: { amount: 100, type: CurrencyTypeInput.CURRENCY_COIN },
          asset,
          type: typeQ as CreateItemInputType,
        },
      },
    });
  };

  // sort items by name
  const sortedItems = _.sortBy(items, ['name']);

  return (
    <>
      <LoadingLogo show={loading || createLoading} />
      {items && (
        <Row className="d-flex">
          <Col>
            <h1 className="text-center">{type}</h1>
            <Button className="w-100 mb-3" variant="success" onClick={handleCreate}>
              Add a new {type}
            </Button>
            <Nav variant="pills" className="mb-3" style={{ maxHeight: '75vh', overflowY: 'auto' }}>
              {Object.values(sortedItems).map((item) => (
                <Nav.Item key={item.id} style={{ width: '100%' }} className="text-center">
                  <LinkContainer to={`${match.path}/${item.id}`}>
                    <Nav.Link>{item.name}</Nav.Link>
                  </LinkContainer>
                </Nav.Item>
              ))}
            </Nav>
          </Col>
          <Col className="col-10">
            <Switch>
              <Route
                path={`${match.path}/:assetId`}
                render={({ match: _match }) => (
                  // @ts-ignore
                  <ItemView item={items[_match.params.assetId]} type={type} refetch={refetch} />
                )}
              />
              <Redirect to={`${match.path}/${Object.keys(items)[0]}`} />
            </Switch>
          </Col>
        </Row>
      )}
    </>
  );
};

export default ItemsView;
