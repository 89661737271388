import React, { useEffect, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { Badge, Button, Nav, Spinner } from 'react-bootstrap';
import { CREATE_VERSION_CONFIG, DELETE_VERSION_CONFIG, GET_ALL_APP_VERSION_CONFIGS } from './graphql';
import {
  AppVersionConfig,
  CreateAppVersionConfigMutation,
  CreateAppVersionConfigMutationVariables,
  DeleteAppVersionConfigMutation,
  DeleteAppVersionConfigMutationVariables,
  GetAppVersionConfigsQuery,
} from '../../../../../__gqltypes__';
import CreateVersionConfigModal from './CreateVersionConfigModal';
import DeleteVersionConfigModal from './DeleteVersionConfigModal';

const VersionControl = () => {
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState<AppVersionConfig | null>(null);
  const [configs, setConfigs] = useState<AppVersionConfig[]>([]);
  const { data, loading } = useQuery<GetAppVersionConfigsQuery>(GET_ALL_APP_VERSION_CONFIGS);
  const [createConfig, { loading: createLoading }] = useMutation<
    CreateAppVersionConfigMutation,
    CreateAppVersionConfigMutationVariables
  >(CREATE_VERSION_CONFIG);
  const [deleteConfig] = useMutation<DeleteAppVersionConfigMutation, DeleteAppVersionConfigMutationVariables>(
    DELETE_VERSION_CONFIG
  );

  useEffect(() => {
    setConfigs(data?.getAppVersionConfigs.sort((a, b) => (a.version > b.version ? 0 : 1)) ?? []);
    if (!data?.getAppVersionConfigs.length) {
      setShowCreateModal(true);
    } else {
      setShowCreateModal(false);
    }
  }, [data?.getAppVersionConfigs]);

  const handleCreate = (version: string, type: string, platform: string) => {
    createConfig({
      variables: {
        version,
        forceUpdateType: type,
        platform,
      },
    })
      .then(({ data }) => {
        setShowCreateModal(false);
        setConfigs(data?.createAppVersionConfig.sort((a, b) => (a.version > b.version ? 0 : 1)) ?? []);
      })
      .catch(() => {
        alert('Something went wrong :(');
      });
  };

  const handleDelete = () => {
    if (showDeleteModal) {
      deleteConfig({ variables: { version: showDeleteModal.version, platform: showDeleteModal.platform } }).then(
        ({ data }) => {
          setShowDeleteModal(null);
          setConfigs(data?.deleteAppVersionConfig.sort((a, b) => (a.version > b.version ? 0 : 1)) ?? []);
        }
      );
    }
  };

  return (
    <div className="d-flex">
      <CreateVersionConfigModal
        show={showCreateModal && !loading}
        loading={loading || createLoading}
        onHide={() => setShowCreateModal(false)}
        onAdd={handleCreate}
      />
      <DeleteVersionConfigModal
        config={showDeleteModal}
        show={!!showDeleteModal && !loading}
        onHide={() => setShowDeleteModal(null)}
        onConfirm={handleDelete}
      />
      <Nav.Item className="mr-3">
        {loading ? (
          <Spinner animation="border" />
        ) : (
          <>
            <Button className="w-100" variant="success" onClick={() => setShowCreateModal(true)}>
              <i className="fas fa-plus mr-2" />
              Add NVS Campaign
            </Button>
          </>
        )}
      </Nav.Item>
      <div className="mx-auto">
        <Nav variant="pills" style={{ gap: '10px' }}>
          {configs.map((config) => (
            <div key={config.version} className="d-flex">
              <Nav.Item className="w-100 mr-1 position-relative">
                <Nav.Link
                  active
                  eventKey={config.version}
                  className="d-flex justify-content-between text-break align-items-center"
                >
                  <span>{`${config.platform}: ${config.version}`}</span>
                  <Badge className="m-1" pill variant={config.forceUpdateType === 'mandatory' ? 'danger' : 'success'}>
                    {config.forceUpdateType}
                  </Badge>
                </Nav.Link>
              </Nav.Item>
              <Button className="mr-3" variant="danger" onClick={() => setShowDeleteModal(config)}>
                Delete
              </Button>
            </div>
          ))}
        </Nav>
      </div>
    </div>
  );
};

export default VersionControl;
