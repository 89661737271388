import _ from 'lodash';

import { useCallback, useEffect, useState } from 'react';
import { Redirect, Route, useHistory, useRouteMatch, Switch } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';

import { Button, Col, Nav, Row } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';

import {
  CreateEmojiPackMutation,
  CreateEmojiPackMutationVariables,
  EmojiPack,
  GetEmojiPacksQuery, 
  GetEmojiPacksQueryVariables,
} from '../../../../../../__gqltypes__';
import { CREATE_EMOJI_PACK, GET_EMOJI_PACKS } from '../../../components/apolloQueries';
import { indexItems } from '../components/ItemsView';
import { LoadingLogo } from '../../../../devtools/components/Modal';
import EmojiPackView from '../components/EmojiPackView';
import CreateEmojiPackModal from '../components/CreateEmojiPackModal';
import { UploadFontModal } from '../components/UploadFontModal';

const EmojiPacksView =  <T extends string>({type}: {type: T}): JSX.Element => {
  const match = useRouteMatch();

  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showUploadFontModal, setShowUploadModal] = useState(false);
  const [items, setItems] = useState<Record<string, EmojiPack> | null>(null);
  const { data, loading, refetch } = useQuery<
    GetEmojiPacksQuery,
    GetEmojiPacksQueryVariables
  >(GET_EMOJI_PACKS, { fetchPolicy: 'no-cache' });
  const history = useHistory();

  useEffect(() => {
    if (data) {
      setItems(indexItems(data?.liveOps?.emojiPacks?.list));
    }
  }, [data]);

  const anountOfEP = data?.liveOps?.emojiPacks?.list?.length ?? 0;

  const [createItem, { loading: createLoading }] = useMutation<
    CreateEmojiPackMutation, CreateEmojiPackMutationVariables
    >(CREATE_EMOJI_PACK, { onCompleted: () => refetch() }
  );
  
  const handleCreate = useCallback((name: string) => {
    createItem({
      variables: {
        input: {
          name,
        },
      },
    })
      .then(({ data: newData }) => {
        setShowCreateModal(false);
        refetch().then(() => history.push(`/sp3/liveops/items/emojipacks/${newData?.createEmojiPack.emojiPack.id}`));
      })
      .catch(() => {
        alert('Something went wrong :(');
      });
  }, [createItem, history, refetch]);

  const sortedItems = _.sortBy(items, ['name']);

  return (
    <>
      <LoadingLogo show={loading || createLoading} />
      <CreateEmojiPackModal 
        show={(showCreateModal) && !loading}
        loading={loading || createLoading}
        onHide={() => setShowCreateModal(false)}
        onAdd={handleCreate}
      />
      {showUploadFontModal && (
        <UploadFontModal onCancel={() => setShowUploadModal(false)}/>
      )}
      {items && (
        <Row className="d-flex">
          <Col>
            <h1 className="text-center">{type}</h1>
            <Button className="w-100 mb-3" variant="success" onClick={() => setShowCreateModal(true)}>
              Add a new {type}
            </Button>
            <Button className="w-100 mb-3" variant="primary" onClick={() => setShowUploadModal(true)}>
              Upload font
            </Button>
            <Nav variant="pills" className="mb-3" style={{ maxHeight: '75vh', overflowY: 'auto' }}>
              {Object.values(sortedItems).map((item) => (
                <Nav.Item key={item.id} style={{ width: '100%' }} className="text-center">
                  <LinkContainer to={`${match.path}/${item.id}`}>
                    <Nav.Link>{item.name}</Nav.Link>
                  </LinkContainer>
                </Nav.Item>
              ))}
            </Nav>
          </Col>
          <Col className="col-10">
            <Switch>
              <Route
                path={`${match.path}/:assetId`}
                render={({ match: _match }) => {
                  return !!anountOfEP && <EmojiPackView item={items[_match.params.assetId]} refetch={refetch} />;
                }}
              />
              <Redirect to={`${match.path}/${Object.keys(items)[0]}`} />
            </Switch>
          </Col>
        </Row>
      )}
    </>
  );
};

export default EmojiPacksView;