import { gql } from '@apollo/client';

export const GET_NUMBER_OF_RECIPIENTS = gql`
  query getNumberOfRecipients($filters: GiftFiltersInput!) {
    getPotentialNumberOfRecipients(filters: $filters) {
      count
      metadataList
    }
  }
`;

export const DRAFT_GIFT_FRAGMENT = gql`
  fragment DraftGiftFragment on IapGiftDraft {
    id
    created
    sendDate
    filters {
      appVersion
      platform
      region
      country
      age
      gender
      subTier
      daysSinceInstall
      isSpender
      specificUsers
      daysSinceLastOnline
    }
    rewards {
      itemId
      type
      amount
    }
    sku
    messageId
    recipientsCount
  }
`;

export const GET_DRAFT_GIFTS = gql`
  query getDraftGifts {
    getDraftGifts {
      ...DraftGiftFragment
    }
  }
  ${DRAFT_GIFT_FRAGMENT}
`;

export const CREATE_GIFT = gql`
  mutation createGiftAdmin(
    $filters: GiftFiltersInput!
    $gifts: [LevelRewardInput!]!
    $messageId: String
    $sendDate: Date
    $recipientsCount: Int
  ) {
    createGiftAdmin(
      filters: $filters
      gifts: $gifts
      messageId: $messageId
      sendDate: $sendDate
      recipientsCount: $recipientsCount
    ) {
      ...DraftGiftFragment
    }
  }
  ${DRAFT_GIFT_FRAGMENT}
`;

export const UPDATE_GIFT = gql`
  mutation updateGiftAdmin(
    $id: ID!
    $filters: GiftFiltersInput!
    $gifts: [LevelRewardInput!]!
    $messageId: String
    $sendDate: Date
    $recipientsCount: Int
  ) {
    updateGiftAdmin(
      id: $id
      filters: $filters
      gifts: $gifts
      messageId: $messageId
      sendDate: $sendDate
      recipientsCount: $recipientsCount
    ) {
      ...DraftGiftFragment
    }
  }
  ${DRAFT_GIFT_FRAGMENT}
`;

export const DELETE_GIFT = gql`
  mutation removeDraftGiftAdmin($id: ID!) {
    removeDraftGiftAdmin(id: $id) {
      isRemoved
    }
  }
`;

export const GET_MESSAGE_TEMPLATES = gql`
  query getAllMessageTemplates {
    getMessageTemplates {
      id
      message
    }
  }
`;

export const CREATE_MESSAGE_TEMPLATE = gql`
  mutation createMessageTemplate($msg: String!) {
    createMessageTemplate(msg: $msg) {
      id
      message
    }
  }
`;

export const EDIT_MESSAGE_TEMPLATE = gql`
  mutation editMessageTemplate($id: ID!, $msg: String!) {
    editMessageTemplate(id: $id, msg: $msg) {
      id
      message
    }
  }
`;

export const DELETE_MESSAGE_TEMPLATE = gql`
  mutation deleteMessageTemplate($id: ID!) {
    deleteMessageTemplate(id: $id) {
      id
      message
    }
  }
`;
