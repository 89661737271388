import * as React from 'react';
import { Button, Col, Form, Modal, ModalProps, Row } from 'react-bootstrap';

type Props = {
  loading: boolean;
  onAdd: (version: string, forceUpdateType: string, platform: string) => void;
} & ModalProps;

const CreateVersionConfigModal = ({ show, onHide, onAdd, loading }: Props) => {
  const [type, setType] = React.useState('optional');
  const [platform, setPlatform] = React.useState('all');

  const [version, setVersion] = React.useState('000.000.000');

  const handleSubmit = () => {
    if (version.length !== 11) {
      alert('Please, enter valid version');
      return;
    }
    onAdd(version, type, platform);
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Body>
        <Row className="mt-2">
          <Col xs={3}>Version</Col>
          <Col xs={6}>
            <Form.Control
              name="version"
              required
              type="text"
              value={version}
              onChange={(e) => setVersion(e.target.value)}
            />
          </Col>
        </Row>
        <Row className="mt-2">
          <Col xs={3}>Platform</Col>
          <Col xs={6}>
            {/* @ts-ignore */}
            <Form.Control
              required
              name="type"
              as="select"
              value={platform}
              onChange={(e) => setPlatform(e.target.value)}
            >
              {['all', 'ios', 'android'].map((type) => (
                <option value={type} key={type}>
                  {type}
                </option>
              ))}
            </Form.Control>
          </Col>
        </Row>
        <Row className="mt-2">
          <Col xs={3}>Type</Col>
          <Col xs={6}>
            {/* @ts-ignore */}
            <Form.Control required name="type" as="select" value={type} onChange={(e) => setType(e.target.value)}>
              {['optional', 'mandatory'].map((type) => (
                <option value={type} key={type}>
                  {type}
                </option>
              ))}
            </Form.Control>
          </Col>
        </Row>
        <Row className="mt-3 pl-1">
          <Button disabled={loading} onClick={handleSubmit}>
            Create
          </Button>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default CreateVersionConfigModal;
