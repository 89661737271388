import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { Badge, Button, Card, Col, Form, Nav, Row, Spinner } from 'react-bootstrap';
import { JsonObjectExpression } from 'typescript';
import moment from 'moment';
import _ from 'lodash';
import {
  CREATE_GIFT,
  CREATE_MESSAGE_TEMPLATE,
  DELETE_MESSAGE_TEMPLATE,
  EDIT_MESSAGE_TEMPLATE,
  GET_DRAFT_GIFTS,
  GET_MESSAGE_TEMPLATES,
  GET_NUMBER_OF_RECIPIENTS,
  UPDATE_GIFT,
} from './graphql';
import {
  CreateGiftAdminMutation,
  CreateGiftAdminMutationVariables,
  CreateMessageTemplateMutation,
  CreateMessageTemplateMutationVariables,
  DeleteMessageTemplateMutation,
  DeleteMessageTemplateMutationVariables,
  EditMessageTemplateMutation,
  EditMessageTemplateMutationVariables,
  GetAllMessageTemplatesQuery,
  GetAllMessageTemplatesQueryVariables,
  GetDashboardDataQueryVariables,
  GetDraftGiftsQuery,
  GetNumberOfRecipientsQuery,
  GetNumberOfRecipientsQueryVariables,
  GiftFiltersInput,
  ItemAvailability,
  LevelRewardInput,
  MessageTemplate,
  UpdateGiftAdminMutation,
  UpdateGiftAdminMutationVariables,
} from '../../../../../__gqltypes__';
import { MassGiftFilters } from './MassGiftFilters';
import EditRewardsModal from '../../../liveops/components/EditRewardsModal';
import Chip from '../../../liveops/components/Chip';
import CostDisplay from '../../../../../utils/dashboards/components/widgets/CostDisplay';
import { LoadingLogo } from '../../components/Modal';

const MassGift = () => {
  const [currentGift, setCurrentGift] = useState<any>({
    message: null,
    filters: {},
    rewards: [],
    sendDate: '',
  });

  const [isEditMode, setIsEditMode] = useState(false);
  const [showEditGift, setShowEditGift] = useState(false);
  const [selectedGift, setSelectedGift] = useState<LevelRewardInput | null>(null);
  const [giftIndex, setGiftIndex] = useState(0);

  const [numberOfRecipients, setNumberOfRecipients] = useState(0);

  const [availableMsgTemplates, setAvailableMsgTemplates] = useState<MessageTemplate[]>([]);
  const [newMsgTemplateInEdit, setNewMsgTemplateInEdit] = useState<string>('');
  const [msgTemplateEditOn, setMsgTemplateEditOn] = useState(false);

  const [metadataList, setMetadataList] = useState<string[]>([]);

  const {
    data: draftGiftsData,
    loading: giftsLoading,
    refetch,
  } = useQuery<GetDraftGiftsQuery, GetDashboardDataQueryVariables>(GET_DRAFT_GIFTS);

  const draftGifts = draftGiftsData?.getDraftGifts ?? [];

  const { data: msgTemplates, loading: msgTemplatesLoading } = useQuery<
    GetAllMessageTemplatesQuery,
    GetAllMessageTemplatesQueryVariables
  >(GET_MESSAGE_TEMPLATES);

  const [createMsgTemplate, { loading: createMTLoading }] = useMutation<
    CreateMessageTemplateMutation,
    CreateMessageTemplateMutationVariables
  >(CREATE_MESSAGE_TEMPLATE);

  const [editMsgTemplate, { loading: editMTLoading }] = useMutation<
    EditMessageTemplateMutation,
    EditMessageTemplateMutationVariables
  >(EDIT_MESSAGE_TEMPLATE);

  const [deleteMsgTemplate, { loading: deleteMTLoading }] = useMutation<
    DeleteMessageTemplateMutation,
    DeleteMessageTemplateMutationVariables
  >(DELETE_MESSAGE_TEMPLATE);

  const [getNumberOfRecipients, { loading: numberOfRecipLoading }] = useLazyQuery<
    GetNumberOfRecipientsQuery,
    GetNumberOfRecipientsQueryVariables
  >(GET_NUMBER_OF_RECIPIENTS);

  const [createGift, { loading: sendGiftsLoading }] = useMutation<
    CreateGiftAdminMutation,
    CreateGiftAdminMutationVariables
  >(CREATE_GIFT);

  const [updateGift] = useMutation<UpdateGiftAdminMutation, UpdateGiftAdminMutationVariables>(UPDATE_GIFT);

  useEffect(() => {
    if (!msgTemplatesLoading && msgTemplates) {
      setAvailableMsgTemplates(msgTemplates.getMessageTemplates);
    }
  }, [msgTemplates, msgTemplatesLoading]);

  const metadata: Record<string, JsonObjectExpression> = useMemo(() => {
    if (metadataList.length === 0) {
      return {};
    }
    const m: Record<string, JsonObjectExpression> = {};
    metadataList.forEach((val, index) => {
      if (val) {
        m[index.toString()] = JSON.parse(val);
      }
    });
    return m;
  }, [metadataList]);

  const onRefreshNumber = () => {
    getNumberOfRecipients({
      variables: {
        filters: _.omit(currentGift.filters, '__typename'),
      },
    }).then((value) => {
      const data_ = value.data?.getPotentialNumberOfRecipients;
      setNumberOfRecipients(data_?.count ?? 0);
      setMetadataList(data_?.metadataList ?? []);
    });
  };

  const onCreateGifts = () => {
    if (currentGift.id) {
      updateGift({
        variables: {
          id: currentGift.id,
          gifts: currentGift.rewards.map((r: LevelRewardInput) => _.omit(r, '__typename')),
          filters: _.omit(currentGift.filters, '__typename'),
          messageId: currentGift?.message?.id ?? null,
          sendDate: moment(currentGift.sendDate).toDate(),
          recipientsCount: numberOfRecipients,
        },
      })
        .then((value) => {
          const data_ = value.data?.updateGiftAdmin;
          setCurrentGift({
            ...data_,
            message: availableMsgTemplates.find((t) => t.id === data_?.messageId),
            sendDate: moment(data_?.sendDate).format('YYYY-MM-DDTHH:mm'),
          });
          setNumberOfRecipients(data_?.recipientsCount ?? 0);
          refetch();
        })
        .catch((e) => {
          console.log(e);
        });
    } else {
      createGift({
        variables: {
          gifts: currentGift.rewards,
          filters: currentGift.filters,
          messageId: currentGift?.message?.id ?? null,
          sendDate: moment(currentGift.sendDate).toDate(),
          recipientsCount: numberOfRecipients,
        },
      })
        .then((value) => {
          const data_ = value.data?.createGiftAdmin;
          setCurrentGift({
            ...data_,
            message: availableMsgTemplates.find((t) => t.id === data_?.messageId),
            sendDate: moment(data_?.sendDate).format('YYYY-MM-DDTHH:mm'),
          });
          setNumberOfRecipients(data_?.recipientsCount ?? 0);
          refetch();
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };

  const onChange = useCallback((newFilters: GiftFiltersInput, newSendDate: string | null) => {
    setCurrentGift((prev: any) => ({ ...prev, filters: newFilters, sendDate: newSendDate }));
  }, []);

  const handleAddGift = useCallback(
    (newReward: LevelRewardInput) => {
      if (isEditMode) {
        const newGifts = [...currentGift.rewards];
        newGifts[giftIndex] = {
          type: newReward.type,
          amount: newReward.amount,
          itemId: newReward.itemId ?? '',
        };
        setCurrentGift((prev: any) => ({ ...prev, rewards: newGifts }));
      } else {
        const newGifts = [...currentGift.rewards];
        newGifts.push({
          type: newReward.type,
          amount: newReward.amount,
          itemId: newReward.itemId ?? '',
        });
        setCurrentGift((prev: any) => ({ ...prev, rewards: newGifts }));
      }
      setShowEditGift(false);
    },
    [isEditMode, currentGift.rewards, giftIndex]
  );

  const handleRemoveGift = useCallback(
    (idx: number) => {
      if (idx >= 0 && idx < currentGift.rewards.length) {
        let newGifts = [...currentGift.rewards];
        newGifts = newGifts.filter((val, index) => index !== idx);
        setCurrentGift((prev: any) => ({ ...prev, rewards: newGifts }));
      }
    },
    [currentGift.rewards]
  );

  const handleShowEditGift = useCallback(
    (idx: number, isEdit: boolean) => {
      setIsEditMode(isEdit);
      if (isEdit) {
        const rew = currentGift.rewards[idx];
        const levelReward: LevelRewardInput = {
          amount: rew.amount,
          type: rew.type,
          itemId: rew.itemId,
        };
        setSelectedGift(levelReward);
      } else {
        setSelectedGift(null);
      }
      setGiftIndex(idx);
      setShowEditGift(true);
    },
    [currentGift.rewards]
  );

  const handleChangeGiftMessage = useCallback(
    (id: string, inEditMode: boolean) => {
      if (id === 'None') {
        setCurrentGift((prev: any) => ({ ...prev, message: null }));
        setNewMsgTemplateInEdit('');
        return;
      }
      const newMsg = availableMsgTemplates.find((val) => val.id === id);
      if (newMsg) {
        setCurrentGift((prev: any) => ({ ...prev, message: newMsg }));
        if (inEditMode) {
          setNewMsgTemplateInEdit(newMsg.message);
        }
      }
    },
    [availableMsgTemplates]
  );

  const handleCreateNewMsgTemplate = () => {
    createMsgTemplate({
      variables: {
        msg: newMsgTemplateInEdit,
      },
    }).then((mutRes) => {
      setAvailableMsgTemplates(mutRes.data?.createMessageTemplate ?? []);
    });
  };

  const handleEditNewMsgTemplate = () => {
    if (currentGift.message) {
      editMsgTemplate({
        variables: {
          id: currentGift.message,
          msg: newMsgTemplateInEdit,
        },
      }).then((mutRes) => {
        const newTmps = mutRes.data?.editMessageTemplate ?? [];
        setAvailableMsgTemplates(newTmps);
        const newMessage = newTmps.find((value) => value.id === currentGift.message.id);
        if (newMessage) {
          setCurrentGift((prev: any) => ({ ...prev, message: newMessage }));
        }
      });
    }
  };

  const handleDeleteNewMsgTemplate = useCallback(() => {
    if (currentGift.message) {
      deleteMsgTemplate({
        variables: {
          id: currentGift.message.id,
        },
      }).then((mutRes) => {
        setAvailableMsgTemplates(mutRes.data?.deleteMessageTemplate ?? []);
        setCurrentGift((prev: any) => ({ ...prev, message: null }));
        setNewMsgTemplateInEdit('');
      });
    }
  }, [currentGift.message, deleteMsgTemplate]);

  const addGift = () => {
    setCurrentGift({ rewards: [], filters: {}, message: null, sendDate: '' });
    setMetadataList([]);
    setNumberOfRecipients(0);
  };

  const setGift = (id: string) => {
    const data_ = draftGifts.find((g) => g.id === id);
    setCurrentGift({
      ...data_,
      message: availableMsgTemplates.find((t) => t.id === data_?.messageId),
      sendDate: moment(data_?.sendDate).format('YYYY-MM-DDTHH:mm'),
    });
    setNumberOfRecipients(data_?.recipientsCount ?? 0);
  };

  const mainLoading = numberOfRecipLoading || sendGiftsLoading;
  const msgTemplateLoading = createMTLoading || editMTLoading || deleteMTLoading;
  return (
    <div className="d-flex">
      <div className="col-2">
        <Nav.Item className="mb-3">
          {giftsLoading ? (
            <Spinner animation="border" />
          ) : (
            <>
              <Button className="w-100" variant="success" onClick={addGift}>
                <i className="fas fa-plus mr-2" />
                Create gift
              </Button>
            </>
          )}
        </Nav.Item>
        <Nav variant="pills">
          {draftGifts
            .slice()
            .sort((a, b) => b.sendDate - a.sendDate)
            .map((gift) => (
              <Nav.Item onClick={() => setGift(gift.id)} key={gift.id} className="w-100 position-relative">
                <Nav.Link
                  active={currentGift.id === gift.id}
                  eventKey={gift.id}
                  className="d-flex justify-content-between text-break align-items-center"
                >
                  <span>{moment(gift.sendDate).format('DD-MM-YYYY HH:mm')}</span>
                  <Badge
                    className="m-1"
                    pill
                    variant={moment(gift.sendDate).isSameOrBefore(moment.now()) ? 'secondary' : 'success'}
                  >
                    {moment(gift.sendDate).isSameOrBefore(moment.now()) ? 'Sent' : 'Pending'}
                  </Badge>
                </Nav.Link>
              </Nav.Item>
            ))}
        </Nav>
      </div>
      <Card className="shadow mb-4 w-100">
        <EditRewardsModal
          isEdit={isEditMode}
          tier={null}
          show={showEditGift}
          level={0}
          reward={selectedGift}
          handleSave={handleAddGift}
          handleClose={() => setShowEditGift(false)}
          disableCurrencySpecialEvent
          disableSubscriptionDay={false}
          disableMonthlyBuff
          enableItemLevelEdit
          enableExtendedFragmentSelector
          liveOpsAvailabilities={[ItemAvailability.EARN]}
        />
        <MassGiftFilters
          filters={currentGift.filters}
          onChange={onChange}
          sendDate={currentGift.sendDate}
          onRefresh={onRefreshNumber}
        />

        <Card className="m-2 border border-secondary">
          <Card.Body>
            {mainLoading ? (
              <LoadingLogo show={mainLoading} />
            ) : (
              <>
                <CostDisplay metadata={metadata} />
                <Row>
                  <Form.Label className="font-weight-bold ml-3">Number of recipients: {numberOfRecipients}</Form.Label>
                </Row>
              </>
            )}
          </Card.Body>
        </Card>

        <Card className="m-2 border border-secondary">
          <Card.Body>
            <Row className="ml-1 mb-4 justify-content-start">
              <Button
                variant="success"
                className="mr-2"
                onClick={() => handleShowEditGift(currentGift.rewards.length, false)}
              >
                <i className="fas fa-plus mr-2" />
                Add Reward
              </Button>
              <Button
                onClick={onCreateGifts}
                disabled={currentGift.rewards.length === 0 || currentGift.sendDate.length === 0}
              >
                <i className="fas fa-gift mr-2" />
                Save
              </Button>
            </Row>
            <Row className="mb-3">
              <Col className="d-flex flex-column align-items-center">
                <Form.Label className="font-weight-bold">Rewards</Form.Label>
                {currentGift.rewards.map((gift: LevelRewardInput, index: number) => {
                  return (
                    <Row key={index} className="mt-2 align-items-end">
                      <Chip
                        reward={gift}
                        canDelete
                        btnAction={() => handleRemoveGift(index)}
                        globalAction={() => handleShowEditGift(index, true)}
                      />
                    </Row>
                  );
                })}
              </Col>
            </Row>
          </Card.Body>
        </Card>

        <Card className="m-2 border border-secondary">
          <Card.Body>
            <Form.Group>
              {msgTemplateLoading ? (
                <LoadingLogo show={msgTemplateLoading} />
              ) : (
                <>
                  <Form.Row>
                    <Form.Label className="ml-1">Current Message:</Form.Label>
                    <Form.Label>{currentGift.message ? currentGift.message.message : ''}</Form.Label>
                  </Form.Row>

                  <Form.Label>Choose Message</Form.Label>
                  <Form.Control
                    as="select"
                    value={currentGift.message?.id ?? 'None'}
                    className="mb-4"
                    onChange={(e) => handleChangeGiftMessage(e.target.value, msgTemplateEditOn)}
                  >
                    <option key="None">None</option>
                    {availableMsgTemplates.map((msgTmp) => {
                      return (
                        <option key={msgTmp.id} value={msgTmp.id}>
                          {msgTmp.message}
                        </option>
                      );
                    })}
                  </Form.Control>

                  <Form.Row>
                    <Form.Label className="mr-2 ml-1">Enable Editing Message Template</Form.Label>
                    <Form.Check
                      type="checkbox"
                      checked={msgTemplateEditOn}
                      className="align-self-start"
                      onChange={(e) => {
                        if (!msgTemplateEditOn && currentGift.message) {
                          setNewMsgTemplateInEdit(currentGift.message.message);
                        } else if (msgTemplateEditOn) {
                          setNewMsgTemplateInEdit('');
                        }
                        setMsgTemplateEditOn((val) => !val);
                      }}
                    />
                  </Form.Row>

                  <Form.Label>Create New Message Template</Form.Label>

                  <Form.Control
                    as="textarea"
                    value={newMsgTemplateInEdit}
                    onChange={(e) => setNewMsgTemplateInEdit(e.target.value)}
                  />

                  <Row className="ml-1">
                    <Button
                      onClick={handleCreateNewMsgTemplate}
                      className="mt-4 mr-2"
                      disabled={newMsgTemplateInEdit.length === 0 || msgTemplateEditOn}
                    >
                      <i className="fas fa-plus mr-2" />
                      Create
                    </Button>

                    <Button
                      onClick={handleEditNewMsgTemplate}
                      className="mt-4 mr-2"
                      disabled={newMsgTemplateInEdit.length === 0 || !msgTemplateEditOn}
                    >
                      <i className="fas fa-edit mr-2" />
                      Edit
                    </Button>

                    <Button
                      onClick={handleDeleteNewMsgTemplate}
                      variant="danger"
                      className="mt-4"
                      disabled={!currentGift.message || !msgTemplateEditOn}
                    >
                      <i className="fas fa-trash mr-2" />
                      Delete
                    </Button>
                  </Row>
                </>
              )}
            </Form.Group>
          </Card.Body>
        </Card>
      </Card>
    </div>
  );
};

export default MassGift;
