import { Button, Card, FormCheck } from 'react-bootstrap';
import { MetricType } from '../../../../../../__gqltypes__';
import { AdditionalMeasureName } from '../logic/utils';
import LegendItem, { Variant } from './LegendItem';
import RequestSummary from './RequestSummary';
import {GeneralAggregatedData} from './types';

interface RequestCardProps {
  color: string;
  requestDataset: { label: string; data: GeneralAggregatedData[] };
  timeSelection: { start: Date; end: Date };
  focus?: boolean;
  isPrevious: boolean;
  onFocus: () => void;
  onBlur: () => void;
  additionalMeasuresActivated: AdditionalMeasureName[];
  onCheckMeasureChanges: (name: AdditionalMeasureName, checked: boolean) => void;
  type: MetricType;
  selected: boolean;
  disabledCheck: boolean;
  onCheckGraphChanges: (checked: boolean) => void;
}

export default function RequestCard({
  color,
  requestDataset,
  timeSelection,
  focus,
  onFocus,
  onBlur,
  isPrevious,
  additionalMeasuresActivated,
  onCheckMeasureChanges,
  type,
  selected,
  disabledCheck,
  onCheckGraphChanges
}: RequestCardProps) {
  const isRC = type === MetricType.REDISREQUESTCOUNT;
  return (
    <Card className="w-100 mw-100 mb-2">
      <Card.Header className="d-flex p-1" style={{alignItems: "center"}}>
        <FormCheck
          className="ml-1"
          onChange={(e) => onCheckGraphChanges(e.currentTarget.checked)}
          checked={selected}
          disabled={disabledCheck}
        />
        <div className="rounded mr-2 ml-2 mt-auto mb-auto" style={{ minWidth: 20, minHeight: 20, backgroundColor: color }} />
        <strong className="mt-auto mr-auto mb-auto text-break">{requestDataset.label}</strong>
        <Button onClick={focus ? onBlur : onFocus} className="p-1 ml-1" variant="outline-primary">
          {focus ? <div className="fas fa-search-minus" /> : <div className="fas fa-search-plus" />}
        </Button>
      </Card.Header>
      {focus && 
        (isRC ? (
          <>
            <Card.Body className="pb-1 pt-1">
              <LegendItem
                variant={Variant.CACHE_HIT}
                color={color}
                checkValue={additionalMeasuresActivated.includes(AdditionalMeasureName.CACHE_HIT)}
                checkOnChange={(checked) => onCheckMeasureChanges(AdditionalMeasureName.CACHE_HIT, checked)}
              />
              <LegendItem
                variant={Variant.CACHE_MISS}
                color={color}
                checkValue={additionalMeasuresActivated.includes(AdditionalMeasureName.CACHE_MISS)}
                checkOnChange={(checked) => onCheckMeasureChanges(AdditionalMeasureName.CACHE_MISS, checked)}
              />
              <LegendItem
                variant={Variant.TIMEOUT}
                color={color}
                checkValue={additionalMeasuresActivated.includes(AdditionalMeasureName.TIMEOUT)}
                checkOnChange={(checked) => onCheckMeasureChanges(AdditionalMeasureName.TIMEOUT, checked)}
              />
            </Card.Body>
            <Card.Body className="pb-1 pt-1 border-top">
              <LegendItem variant={Variant.COUNT} color={color} checkValue checkDisabled />
              {isPrevious && <LegendItem variant={Variant.PREVIOUS_COUNT} color={color} checkValue checkDisabled />}
            </Card.Body>
          </>
        ) : (
          <>
            <Card.Body className="pb-1 pt-1">
              <LegendItem
                variant={Variant.MAX}
                color={color}
                checkValue={additionalMeasuresActivated.includes(AdditionalMeasureName.P100)}
                checkOnChange={(checked) => onCheckMeasureChanges(AdditionalMeasureName.P100, checked)}
              />
              <LegendItem
                variant={Variant.P99}
                color={color}
                checkValue={additionalMeasuresActivated.includes(AdditionalMeasureName.P99)}
                checkOnChange={(checked) => onCheckMeasureChanges(AdditionalMeasureName.P99, checked)}
              />
              <LegendItem
                variant={Variant.P95}
                color={color}
                checkValue={additionalMeasuresActivated.includes(AdditionalMeasureName.P95)}
                checkOnChange={(checked) => onCheckMeasureChanges(AdditionalMeasureName.P95, checked)}
              />
              <LegendItem variant={Variant.AVG} color={color} checkValue checkDisabled />
              {isPrevious && <LegendItem variant={Variant.PREVIOUS_AVG} color={color} checkValue checkDisabled />}
            </Card.Body>
            <Card.Body className="pb-1 pt-1 border-top">
              <LegendItem variant={Variant.COUNT} color={color} checkValue checkDisabled />
              {isPrevious && <LegendItem variant={Variant.PREVIOUS_COUNT} color={color} checkValue checkDisabled />}
            </Card.Body>
          </>
        ))}
      {focus && (
        <Card.Body className="pb-1 pt-1 border-top">
          <RequestSummary requestDataset={requestDataset} color={color} type={type} timeSelection={timeSelection} />
        </Card.Body>
      )}
    </Card>
  );
}
