import { useCallback, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useDropzone } from 'react-dropzone';

type Props = {
  onGenerate: (files: Array<File>, onError: () => void) => void;
}

export default function EmojiFactory({onGenerate}: Props): JSX.Element {
  const [files, setFiles] = useState<{file: File; preview: string}[]>([]);

  const onDrop = useCallback((acceptedFiles: File[]) => {
    setFiles(acceptedFiles.map((file) => {
      const obj = {file};
      return Object.assign(obj, {preview: URL.createObjectURL(file)});
    }
    ).sort((a, b) => {
      const regExp = /_(E\w+)\./;
      const codeA = regExp.exec(a.file.name);
      const codeB = regExp.exec(b.file.name);
      if (codeA && codeB) {
        const valA = codeA[1]; 
        const valB = codeB[1];
        return valA < valB ? -1 : valA > valB ? 1 : 0;
      }
      return 0;
    }));
  }, []); 

  const onGen = useCallback(() => {
    onGenerate(files.map((val) => val.file), () => {
      // in case of error we want to clean files in memory.
      setFiles([]);
    });
  }, [onGenerate, files]);
  // maybe it is better to show previews of stored files?

  const thumb = {
    display: 'inline-flex',
    borderRadius: 2,
    border: '1px solid #eaeaea',
    marginBottom: 8,
    marginRight: 8,
    width: 100,
    height: 100,
    padding: 4,
  };
  
  const thumbInner = {
    display: 'flex',
    minWidth: 0,
    overflow: 'hidden'
  };

  const img = {
    display: 'block',
    width: 'auto',
    height: '100%'
  };
  

  const { getRootProps, getInputProps, isDragActive} = useDropzone({ onDrop, accept: '.json,.png' });
  return (
    <>

      {files.length === 0 ? (<div 
        {...getRootProps()} 
        style={isDragActive ? {
            border: '2px solid white',
            backgroundColor: '#4e73df',
            color: 'white',
            borderRadius: '10px',
          } : {
            border: '2px solid #4e73df', borderRadius: '10px'}} 
            className="text-center"
        >
        <p>Emoji Factory</p>
        <input {...getInputProps()} />
        {isDragActive ? <p>Drop Files Here...</p> : <p>Drop files or click here to upload</p>}
      </div>) : (
        <div className="mt-2">
           <Button
           className='mr-2'
            onClick={() => {
              setFiles([]);
            }}
          >
            <i className="fas fa-recycle mr-2" />
            Clear
          </Button>
          <Button
            onClick={onGen}
            variant='success'
          >
            <i className="fas fa-spinner mr-2" />
            Generate
          </Button>
          <div>
            {files.map((data) => {
              return (
              <div style={thumb} key={data.file.name}>
                
                <div style={thumbInner}>
                  <img
                    alt={data.file.name}
                    src={data.preview}
                    style={img}
                    // Revoke data uri after image is loaded
                    onLoad={() => { URL.revokeObjectURL(data.preview);}}
                  />
                </div>
              </div>
              );
            })}
          </div>
        </div>
      )}
    </>
  );
}