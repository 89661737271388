import { useCallback, useState } from "react";
import { Button, Card, Form } from "react-bootstrap";
import { useMutation } from "@apollo/client";

import { DELETE_EMOJI, UPDATE_EMOJI, UPLOAD_EMOJI_ASSET } from "../../../components/apolloQueries";
import { 
  Emoji,
  DeleteEmojiMutation,
  DeleteEmojiMutationVariables,
  UpdateEmojiMutation,
  UpdateEmojiMutationVariables, 
  UploadEmojiAssetMutation,
  UploadEmojiAssetMutationVariables} from "../../../../../../__gqltypes__";
import DeleteItemModal from "./DeleteItemModal";
import DragUpload from "./DragUpload";

type Props = {
  item: Emoji;
  index: number;
  isNew: boolean;
  emojiPackSize: number;
  onUpdate: () => void;
  onDelete: (index: number) => void;
  onMove: (index: number, isUp: boolean) => void;
}

export default function EmojiView({
  item,
  index,
  isNew,
  emojiPackSize,
  onUpdate,
  onDelete,
  onMove
}: Props): JSX.Element {
  const [itemData, setItemData] = useState<Emoji>(item);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const [updateEmoji, {loading: updateLoading}] = useMutation<
    UpdateEmojiMutation,
    UpdateEmojiMutationVariables
  >(UPDATE_EMOJI);

  const [deleteEmoji, { loading: deleteLoading}] = useMutation<
    DeleteEmojiMutation,
    DeleteEmojiMutationVariables
  >(DELETE_EMOJI);

  const [uploadEmoji, { loading: uploadLoading}] = useMutation<
    UploadEmojiAssetMutation,
    UploadEmojiAssetMutationVariables
  >(UPLOAD_EMOJI_ASSET);

  const handleUpdate = useCallback((newData: Emoji) => {
    updateEmoji({ variables: {
      input: {
        id: newData.id,
        name: newData.name,
        asset: newData.asset,
        utfCode: newData.utfCode,
      }
    }}).then(() => {
      onUpdate();
    });
  }, [updateEmoji, onUpdate]);

  const handleDelete = useCallback(() => {
    deleteEmoji({variables: {
      id: itemData.id,
    }}).then(() => {
      onDelete(index);
    });
  }, [deleteEmoji, index, itemData.id, onDelete]);

  const handleUpload = useCallback((file: Blob) => {
    const file_ = file as File;
    const codePoint_ = file_.name.codePointAt(0) ?? 0; 
    const codePoint = codePoint_.toString(16);
    const sliceStr = file_.name.slice(file_.name.indexOf('_'));
    const newName = codePoint.concat(sliceStr);
    const newFile = new File([file], newName);
    uploadEmoji({variables: {
      input: {
        file: newFile,
      }
    }}).then(({data}) => {
      const newData = {...itemData, asset: data?.uploadEmojiAsset.asset ?? ''};
      handleUpdate(newData);
    });
  }, [handleUpdate, itemData, uploadEmoji]);
  const loading = updateLoading || deleteLoading || uploadLoading;

  return (
    <>
      <Card className="mt-3">
      <DeleteItemModal
          title={`Delete "${itemData?.name}" Emoji?`}
          show={showDeleteModal && !loading}
          onHide={() => setShowDeleteModal(false)}
          onConfirm={handleDelete}
        />
        <Card.Header className="text-center d-flex justify-content-between">
          <h4>{itemData?.name}</h4>
          <div>
            <Button className="mr-3" variant="danger" onClick={() => setShowDeleteModal(true)}>
              Delete
            </Button>
            <Button onClick={() => handleUpdate(itemData)}>Save</Button>
            {index === 0 ? null : (
              <Button 
                size="sm" 
                variant="light" 
                onClick={() => onMove(index, true)} 
                style={{borderWidth: 1, borderColor: 'rgba(127, 127, 127, 0.4)', marginBottom: 4}}>
                <i className="fas fa-angle-up" />
              </Button>
            )}
            {index === emojiPackSize - 1 ? null : (
              <Button 
                size="sm"
                variant="light"
                onClick={() => onMove(index, false)}
                style={{borderWidth: 1, borderColor: 'rgba(127, 127, 127, 0.4)'}}>
                <i className="fas fa-angle-down" />
              </Button>
            )}
          </div>
        </Card.Header>
        <Card.Body style={{ height: '100%' }}>
          
            <Form.Group>
              <Form.Label>Id</Form.Label>
              <Form.Control placeholder={itemData.id} disabled />
            </Form.Group>
            <Form.Group>
              <Form.Label>Name</Form.Label>
              <Form.Control
                placeholder=""
                value={itemData.name}
                onChange={(e) => setItemData({ ...itemData, name: e.target.value })}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>UTF code</Form.Label>
              <Form.Control
                placeholder=""
                value={itemData.utfCode}
                disabled={!isNew}
                onChange={(e) => {
                  const utfCode = e.target.value;
                  const newId = `${itemData.id.split('.')[0]}.${utfCode}`;
                  setItemData({ ...itemData, utfCode, id: newId });
                }}
              />
            </Form.Group>
            <DragUpload onUpload={handleUpload} />
            <Form.Group>
              <Form.Label>Asset</Form.Label>
              <Form.Control
                placeholder=""
                value={itemData.asset}
                disabled
              />
            </Form.Group>
        </Card.Body>      
      </Card>
    </>
  );
}