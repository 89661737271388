import { gql } from '@apollo/client';

const ESSENTIAL_METRICS_FRAGMENT = gql`
  fragment EssentialMetrics on NumericAggregatedMetricsSerie {
    title
    bytesProcessed
    timestamp
    start
    end
    records {
      name
      datetime
      average
      P50
      P95
      P99
      P100
      count
      std
    }
  }
`;

const FULL_METRICS_FRAGMENT = gql`
fragment FulllMetrics on NumericAggregatedMetricsSerie {
  timestamp
  start
  end
  records {
    type
    name
    datetime
    timestamp
    average
    P0
    P10
    P20
    P30
    P40
    P50
    P71
    P85
    P95
    P99
    P100
    count
    std
  }
}
`;

const ESSENTIAL_RC_METRICS_FRAGMENT = gql`
  fragment EssentialRedisCountMetrics on NumericAggregatedRedisCountMetricsSerie {
    title
    bytesProcessed
    timestamp
    start
    end
    records {
      name
      datetime
      count
      cache_hit
      cache_miss
      timeout
      cache_miss_ratio
      timeout_ratio
    }
  }
`;


/**
 * Essential metrics, to be displayed as a graph
 */

export const MONITORING_PREVIOUS = gql`
  query MonitoringPrevious($input: PreviousAggregatedMetricsInput!) {
    monitoring: monitoringPrevious(input: $input) {
      ...EssentialMetrics
      ...EssentialRedisCountMetrics
    }
  }

  ${ESSENTIAL_METRICS_FRAGMENT}
  ${ESSENTIAL_RC_METRICS_FRAGMENT}
`;

export const MONITORING_BETWEEN = gql`
  query MonitoringBetween($input: BetweenAggregatedMetricsInput!) {
    monitoring: monitoringBetween(input: $input) {
      ...EssentialMetrics
      ...EssentialRedisCountMetrics
    }
  }
  ${ESSENTIAL_METRICS_FRAGMENT}
  ${ESSENTIAL_RC_METRICS_FRAGMENT}
`;

export const MONITORING_SPECIFIC_REQUESTS_PREVIOUS = gql`
  query MonitoringSpecificRequestsPrevious($input: RequestPreviousAggregatedMetricsInput!) {
    monitoring: monitoringSpecificRequestsPrevious(input: $input) {
      ...EssentialMetrics
      ...EssentialRedisCountMetrics
    }
  }

  ${ESSENTIAL_METRICS_FRAGMENT}
  ${ESSENTIAL_RC_METRICS_FRAGMENT}
`;

export const MONITORING_SPECIFIC_REQUESTS_BETWEEN = gql`
  query MonitoringSpecificRequestsBetween($input: RequestBetweenAggregatedMetricsInput!) {
    monitoring: monitoringSpecificRequestsBetween(input: $input) {
      ...EssentialMetrics
      ...EssentialRedisCountMetrics
    }
  }

  ${ESSENTIAL_METRICS_FRAGMENT}
  ${ESSENTIAL_RC_METRICS_FRAGMENT}
`;

/**
 * Request names, for autocompletion
 */

export const MONITORING_ALL_REQUEST_NAMES = gql`
  query MonitoringRequestNames($input: MonitoringRequestNamesInput!) {
    monitoringRequestNames(input: $input)
  }
`;

/**
 * Full metrics, for csv download
 */

export const FULL_MONITORING_PREVIOUS = gql`
  query FullMonitoringPrevious($input: PreviousAggregatedMetricsInput!) {
    monitoring: monitoringPrevious(input: $input) {
      ...FulllMetrics
      ...EssentialRedisCountMetrics
    }
  }

  ${FULL_METRICS_FRAGMENT}
  ${ESSENTIAL_RC_METRICS_FRAGMENT}
`;

export const FULL_MONITORING_BETWEEN = gql`
  query FullMonitoringBetween($input: BetweenAggregatedMetricsInput!) {
    monitoring: monitoringBetween(input: $input) {
      ...FulllMetrics
      ...EssentialRedisCountMetrics
    }
  }

  ${FULL_METRICS_FRAGMENT}
  ${ESSENTIAL_RC_METRICS_FRAGMENT}
`;

export const FULL_MONITORING_SPECIFIC_REQUESTS_PREVIOUS = gql`
  query FullMonitoringSpecificRequestsPrevious($input: RequestPreviousAggregatedMetricsInput!) {
    monitoring: monitoringSpecificRequestsPrevious(input: $input) {
      ...FulllMetrics
      ...EssentialRedisCountMetrics
    }
  }

  ${FULL_METRICS_FRAGMENT}
  ${ESSENTIAL_RC_METRICS_FRAGMENT}
`;

export const FULL_MONITORING_SPECIFIC_REQUESTS_BETWEEN = gql`
  query FullMonitoringSpecificRequestsBetween($input: RequestBetweenAggregatedMetricsInput!) {
    monitoring: monitoringSpecificRequestsBetween(input: $input) {
      ...FulllMetrics
      ...EssentialRedisCountMetrics
    }
  }

  ${FULL_METRICS_FRAGMENT}
  ${ESSENTIAL_RC_METRICS_FRAGMENT}
`;

