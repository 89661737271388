import { gql } from '@apollo/client';

export const GET_ALL_APP_VERSION_CONFIGS = gql`
  query getAppVersionConfigs {
    getAppVersionConfigs {
      version
      platform
      forceUpdateType
      numericVersion
    }
  }
`;

export const CREATE_VERSION_CONFIG = gql`
  mutation createAppVersionConfig($version: String!, $forceUpdateType: String!, $platform: String!) {
    createAppVersionConfig(version: $version, forceUpdateType: $forceUpdateType, platform: $platform) {
      version
      platform
      forceUpdateType
      numericVersion
    }
  }
`;

export const DELETE_VERSION_CONFIG = gql`
  mutation deleteAppVersionConfig($version: String!, $platform: String!) {
    deleteAppVersionConfig(version: $version, platform: $platform) {
      version
      platform
      forceUpdateType
      numericVersion
    }
  }
`;
