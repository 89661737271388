import { useCallback, useEffect, useState } from 'react';
import { Badge, Button, Form, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useLazyQuery } from '@apollo/client';

import {
  CurrencyBoostPack,
  CurrencyBundlePack,
  CurrencyCoinPack,
  CurrencyDiamondPack,
  PurchaseLimitEnum,
  SaleConfig,
  SaleTagType,
  SearchType,
} from '../../../../../../__gqltypes__';
// eslint-disable-next-line import/no-cycle
import { AppSalesConfig, SaleType, TargetedProduct } from '../typedef';
import { getSaleDiscount, getSaleType, stringToEnum } from '../utils';
import { VANITY_ITEM_BY_ID_QUERY } from '../../../components/apolloQueries';

type Props = {
  fetchedSale: SaleConfig;
  readonly: boolean;
  appSalesConfig: AppSalesConfig;
  handleSaleUpdate: (updatedSale: SaleConfig & { itemId?: string }) => SaleConfig;
  handleSaleDelete: (deleteSale: SaleConfig) => void;
};

export function SaleRow({ fetchedSale, readonly, appSalesConfig, handleSaleUpdate, handleSaleDelete }: Props) {
  const allSaleConfigs = appSalesConfig.saleConfigs;
  const targetedProducts = appSalesConfig.targetedProducts;

  /**
   * STATE
   */
  const [sale, setSale] = useState<SaleConfig & { itemId?: string }>(fetchedSale);
  const [saleConfig, setSaleConfig] = useState(allSaleConfigs[0]);
  const [targetedProduct, setTargetedProduct] = useState(targetedProducts[0]);
  const [isValidItemId, setIsValidItemId] = useState(false);

  const [fetchData] = useLazyQuery(VANITY_ITEM_BY_ID_QUERY, {
    onCompleted: ({ item }) => {
      if (item) {
        const updatedSale = {
          ...sale,
          itemId: item.id,
          oldPrice: item.cost?.amount,
          newPrice:
            sale.offerPercentage && item.cost?.amount ? (item.cost.amount * (100 - sale.offerPercentage)) / 100 : null,
          ruleMaximumNumberOfClaim: 1,
          purchaseLimitType: PurchaseLimitEnum.PER_SALE,
        };
        setSale(updatedSale);
        handleSaleUpdate(updatedSale);
        setIsValidItemId(true);
      } else {
        setIsValidItemId(false);
      }
    },
  });

  const isVanityItem =
    sale.targetedProductId && ['sticker', 'frame', 'vinyl'].includes(sale.targetedProductId.split('.')[0]);

  useEffect(() => {
    // [TO_DO] Add idLike check?
    if (sale.itemId && isVanityItem) {
      fetchData({
        variables: {
          property: sale.itemId,
          type: SearchType.BY_ID,
        },
      });
    }
  }, [fetchData, isVanityItem, sale.itemId]);

  /**
   * Helpers function
   */
  function findSaleConfigById(
    saleProductId: string
  ):
    | CurrencyDiamondPack
    | CurrencyCoinPack
    | CurrencyBoostPack
    | { id: string; amount: number; offerPercentage: number } {
    return allSaleConfigs.find((saleConfigsItem) => saleProductId.includes(saleConfigsItem.id)) ?? allSaleConfigs[0];
  }
  function findTaregetedProductById(targetedProductId: string): TargetedProduct {
    return targetedProducts.find((saleConfigsItem) => saleConfigsItem.id === targetedProductId) ?? targetedProducts[0];
  }
  function getProductName(currentSaleType: SaleType, currentTargetedProduct: TargetedProduct): string {
    const isBoost = 'boostType' in currentTargetedProduct;
    const newProductName = (isBoost ? currentTargetedProduct.boostType ?? currentSaleType : currentSaleType)
      ?.toLowerCase()
      .split('_')
      .join(' ')
      .replace(/^\w/, (c) => c.toUpperCase());
    if (currentTargetedProduct.amount && currentTargetedProduct.amount > 1) {
      return `${newProductName}s`;
    }
    return newProductName;
  }

  function formatCurency(typename: 'CurrencyDiamond' | 'CurrencyCoin'): string {
    switch (typename) {
      case 'CurrencyDiamond':
        return 'diamonds';
      case 'CurrencyCoin':
        return 'coins';
      default:
        return '';
    }
  }

  function addPriceUnit(price: number | string): string {
    if (!price) {
      return '';
    }

    if (isVanityItem) {
      return `${price} diamonds`;
    }

    // if cost not in saleConfig, it's diamond sos we add $
    if (!('cost' in saleConfig) || !saleConfig.cost?.__typename) {
      return `$ ${price}`;
    }
    return `${price} ${formatCurency(saleConfig.cost.__typename)}`;
  }

  // check if for a product, a discount is available.
  function isDiscountValid(product: TargetedProduct, discount: number) {
    for (const saleConfigItem of product.saleConfigs) {
      if (getSaleDiscount(saleConfigItem.id) === discount) {
        return true;
      }
    }
    return false;
  }

  /**
   * CHANGES HANDLERS FUNCTIONS
   */

  const handleChangeTag = useCallback(
    (event) => {
      const newTag = stringToEnum(event.target.value, SaleTagType);
      handleSaleUpdate({
        ...sale,
        tag: newTag,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [sale]
  );

  const handleChangeDiscount = useCallback(
    (event) => {
      // Update offerPercentage, newPrice and saleProductId
      const newDiscount = parseInt(event.target.value);
      const newSaleProductId = `${targetedProduct.id}.${newDiscount}`;
      const newSaleConfig = findSaleConfigById(newSaleProductId);
      const updatedSale = {
        ...sale,
        newPrice:
          'cost' in newSaleConfig
            ? newSaleConfig.cost?.amount
            : isVanityItem && sale.oldPrice
            ? (sale.oldPrice * (100 - newDiscount)) / 100
            : -1,
        offerPercentage: newDiscount,
        saleProductId: newSaleProductId,
      };
      setSale(updatedSale);
      handleSaleUpdate(updatedSale);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [sale, targetedProduct]
  );

  const handleChangeTargetedProduct = useCallback(
    (event) => {
      const newTargetedProductId = event.target.value;
      const newTargetedProduct = findTaregetedProductById(newTargetedProductId);

      // If discount is valid, return it, else return the first valid discount
      const newDiscount = isDiscountValid(newTargetedProduct, sale.offerPercentage as number)
        ? sale.offerPercentage
        : getSaleDiscount(newTargetedProduct.saleConfigs[0].id);
      // Get the new config related to the new targeted product and discount
      const newSaleConfig = findSaleConfigById(`${newTargetedProductId}.${newDiscount}`);

      handleSaleUpdate({
        ...sale,
        oldPrice: -1,
        newPrice: 'cost' in newSaleConfig ? newSaleConfig.cost?.amount : -1,
        offerPercentage: newDiscount,
        saleProductId: newSaleConfig.id,
        targetedProductId: newTargetedProductId,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [sale]
  );

  /**
   * STATE UPDATE LOGIC
   */

  useEffect(() => {
    const newSaleConfig = findSaleConfigById(fetchedSale.saleProductId ?? '');
    const newTargetedProduct = findTaregetedProductById(fetchedSale.targetedProductId ?? '');
    const newSaleProductId = fetchedSale.saleProductId ?? newSaleConfig.id;
    // Set sale, add default values for undefined fields.
    setSale({
      ...fetchedSale,
      oldPrice: fetchedSale.oldPrice ?? -1,
      newPrice: fetchedSale.newPrice ?? ('cost' in newSaleConfig ? newSaleConfig.cost?.amount : -1),
      offerPercentage: fetchedSale.offerPercentage ?? getSaleDiscount(newSaleProductId),
      saleProductId: newSaleProductId,
      targetedProductId: fetchedSale.targetedProductId ?? targetedProducts[0].id,
      purchaseLimitType: fetchedSale.purchaseLimitType ?? PurchaseLimitEnum.UNLIMITED,
    });
    setSaleConfig(newSaleConfig);
    setTargetedProduct(newTargetedProduct);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchedSale]);

  return (
    <tr>
      <td style={{ verticalAlign: 'middle' }}>
        <Form.Control
          disabled={readonly}
          name="tag"
          as="select"
          value={sale.targetedProductId as string}
          onChange={handleChangeTargetedProduct}
        >
          {appSalesConfig.targetedProducts.map((value, index) => {
            return (
              <option key={`${value.id}-${index}`} value={value.id}>
                {`${value.amount} ${getProductName(getSaleType(value.id), value)}`}
              </option>
            );
          })}
        </Form.Control>
        {isVanityItem && (
          <div className="d-flex align-items-center">
            <Form.Control
              placeholder="Item ID"
              value={sale.itemId ?? ''}
              onChange={(e) => handleSaleUpdate({ ...sale, itemId: e.target.value })}
              type="text"
            />
            {isValidItemId ? (
              <OverlayTrigger placement="bottom" overlay={<Tooltip id="tooltip">Item id is correct.</Tooltip>}>
                <Badge variant="success">&#10003;</Badge>
              </OverlayTrigger>
            ) : (
              <OverlayTrigger placement="bottom" overlay={<Tooltip id="tooltip">Wrong item id!</Tooltip>}>
                <Badge variant="danger">&#10007;</Badge>
              </OverlayTrigger>
            )}
          </div>
        )}
      </td>
      <td className="d-flex" style={{ verticalAlign: 'middle' }}>
        {sale.purchaseLimitType !== PurchaseLimitEnum.UNLIMITED && (
          <Form.Control
            disabled={!!isVanityItem}
            style={{ width: '40%' }}
            placeholder="Quantity"
            value={sale.ruleMaximumNumberOfClaim ?? ''}
            onChange={(e) => handleSaleUpdate({ ...sale, ruleMaximumNumberOfClaim: +e.target.value })}
            type="text"
          />
        )}
        <Form.Control
          disabled={!!isVanityItem}
          name="tag"
          as="select"
          value={sale.purchaseLimitType ?? PurchaseLimitEnum.UNLIMITED}
          onChange={(e) => handleSaleUpdate({ ...sale, purchaseLimitType: e.target.value as any })}
        >
          {Object.entries(PurchaseLimitEnum).map(([key, value], index) => {
            return (
              <option key={`${key}-${index}`} value={value}>
                {key}
              </option>
            );
          })}
        </Form.Control>
      </td>
      <td style={{ verticalAlign: 'middle' }}>
        <Form.Control
          disabled={readonly}
          name="tag"
          as="select"
          value={sale.offerPercentage as number}
          onChange={handleChangeDiscount}
        >
          {targetedProduct.saleConfigs.map((item, index) => {
            return (
              <option key={`${item.id}-${index}`} value={getSaleDiscount(item.id)}>
                {`${getSaleDiscount(item.id)}%`}
              </option>
            );
          })}
        </Form.Control>
      </td>
      <td style={{ verticalAlign: 'middle' }}>
        {addPriceUnit(sale.oldPrice === -1 ? '--' : (sale.oldPrice as number))}
      </td>
      <td style={{ verticalAlign: 'middle' }}>
        {addPriceUnit(sale.newPrice === -1 ? '--' : (sale.newPrice as number))}
      </td>
      {/* Not implemented in the frontend yet */}
      {/* <td style={{ verticalAlign: 'middle' }}>
        <Form.Control disabled={readonly} name="tag" as="select" value={sale.tag} onChange={handleChangeTag}>
          {Object.entries(SaleTagType).map(([key, value]) => (
            <option key={`${sale.id}-tag-${key}`} value={key}>
              {value}
            </option>
          ))}
        </Form.Control>
      </td> */}
      <td>
        <Button
          onClick={() => {
            handleSaleDelete(sale);
          }}
          variant="danger"
          disabled={readonly}
        >
          <i className="fas fa-trash mr-2" />
        </Button>
      </td>
    </tr>
  );
}
